<template>
  <div id="misc">
    <div class="page-title text-center px-5">
      <h2 class="text-2xl font-weight-semibold text--primary d-flex align-center justify-center">
        <span class="me-2">Sorry, something went wrong.</span>
        <v-icon color="error">
          {{ icons.mdiAlert }}
        </v-icon>
      </h2>
      <p
        v-if="msg"
        class="text-sm"
      >
        {{ msg }}
      </p>

      <v-btn
        color="primary"
        :to="homeUrl()"
        class="mb-4"
        style="margin-top:30px"
      >
        Back to home
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mdiAlert } from '@mdi/js'
import * as QuasrHelper from '@quasr-io/helper'

export default {

  methods: {
    homeUrl() {
      localStorage.clear()

      return `/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/logout`
    },
  },

  /**
   * setup
   */
  setup() {
    const QUASR_ENV = QuasrHelper.getTenantAndClient()
    const searchParams = new URLSearchParams(document.location.search)
    const msg = searchParams.get('msg')

    return {
      QUASR_ENV,
      msg,
      icons: {
        mdiAlert,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/misc.scss';
</style>
